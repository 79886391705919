.container_add {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
}

.container_add form {
        width: 100%;
        max-width: 500px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.container_add form span:nth-child(2) {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-bottom: 20px;
}

.container_add form span:nth-child(2) img {
        width: 100%;
        height: auto;
        max-height: 250px;
        object-fit: cover;
        border-radius: 5px;
}

.container_add form h3 {
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 20px;
}
.container_add form select,
.container_add form input {
        width: 100%;
        height: 35px;
        margin: 10px 0;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 10px;
}

.container_add form label {
        position: relative;
        width: 100%;
        text-align: left;
}

.container_add form span:nth-last-child(2) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start !important;
        padding-bottom: 20px;
}

.container_add form span:nth-last-child(2) label {
        width: 110px;
        font-size: 1.1em;
}

.container_add form span:nth-last-child(2) input {
        width: 20px;
        height: 20px;
}

.container_add form span {
        width: 100%;
        display: flex;
        place-content: center;
        gap: 20px;
}

.container_add form span button {
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
}

.container_add form span button:first-child {
        background-color: green;
        color: #fff;
}

.container_add form span button:last-child {
        background-color: red;
        color: #fff;
}