.container_menu {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 40px;
        background-color: #F3F3F3;
}

.container_menu>div {
        display: flex;
        width: 100%;
        display: flex;
        max-width: 350px;
        flex-direction: column;
}

.container_menu>div>h3 {
        width: 100%;
        text-align: center;
        padding: 20px;
}

.container_menu>div>span {
        padding: 20px 0;

}

.container_menu>div>span strong{
        color: red;
}

.container_menu>div>span ul {
        color: var(--primaryColor);
}

.box_li {
        width: 100%;
        display: flex;
        row-gap: 20px;
        list-style-type: none;
        flex-direction: column;
}

.li {
        position: relative;
        width: 100%;
        height: 35px;
        background-color: var(--primaryColor);
        border-radius: 5px;
        color: #FFFFFF;
}

.li span {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
}

.li span p {
        padding-left: 10px;
}

.li span input {
        width: 100%;
        height: 100%;
        border: none;
        background-color: var(--primaryBackground);
        color: var(--primaryColor);
        padding: 2px;
        border-radius: 5px;
        font-weight: bold;
}

.container_button_reorder{
        display: flex;
        position: fixed;
        top: 70px;
        right: 20px;
}
.container_button_reorder > button{
        padding: 10px;
        background-color: var(--primaryColor);
        color: white;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        border: none;
        box-shadow: 1px 1px 1px 2px #F3F3F3;
}