.container_cash_control {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.container_cash_control form{
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 10px;
}
.container_cash_control form h3{
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        padding: 10px;
}

.container_cash_control form span{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
}

.container_cash_control form span select,
.container_cash_control form span input{
        width: 100%;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
}

.container_cash_control form span textarea{
        max-width: 500px;
        width: 100%;
        height: 100px;
        min-height: 100px;
        max-height: 100px;
        border-radius: 10px;
        border: 1px solid #ccc;
}
.container_cash_control form span:last-child{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
}

.container_cash_control form span:last-child button{
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: none;
        background-color: var(--primaryColor);
        color: #fff;
        cursor: pointer;
        font-weight: bold;
}

.container_cash_control form span:last-child button:last-child{
        background-color: red;
}
