.container_searcher {
        display: flex;
        width: 100%;
        height: auto;
        padding-bottom: 20px;

}

.container_searcher form {
        width: 100%;
        display: flex;
}

.container_searcher form input {
        width: calc(100% - 30px);
        height: 30px;
        border: 1px solid #d8d8d8;
        border-radius: 5px 0 0 5px;
        font-size: 1em;
        color: #000000;
        padding-left: 20px;
}

.container_searcher form button {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 0 5px 5px 0;
        font-weight: bold;
        cursor: pointer;
        color: #FFFFFF;
        border: 1px solid #d8d8d8;

}

.container_searcher form button svg {

        width: 1.4em;
        height: 1.4em;
}


.container_filters {
        width: 100%;
        display: flex;
        padding: 5px;
        gap: 5px;
        flex-wrap: wrap;
}


.container_filters>button[type="button"] {
        width: 30px;
        height: 30px;
        aspect-ratio: 1 / 1;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
}

.container_filters form {

        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
}

.container_filters form span {
        width: calc(50% - 10px);
}

.container_filters form input,
.container_filters form select {
        width: 50%;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 10px;
}

.container_filters form input[type="search"] {
        width: calc(100% - 40px);
}

.container_filters form button {
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
}

.switch {
        --button-width: 3.5em;
        --button-height: 27px;
        --toggle-diameter: 1.3em;
        --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
        --toggle-shadow-offset: 10px;
        --toggle-wider: 3em;
        --color-grey: #cccccc;
        --color-green: var(--primaryColor);
        display: flex;
        align-items: center;
        gap: 10px;
}

.slider {
        display: inline-block;
        width: var(--button-width);
        height: var(--button-height);
        background-color: var(--color-grey);
        border-radius: calc(var(--button-height) / 2);
        position: relative;
        transition: 0.3s all ease-in-out;
}

.slider::after {
        content: "";
        display: inline-block;
        width: var(--toggle-diameter);
        height: var(--toggle-diameter);
        background-color: #fff;
        border-radius: calc(var(--toggle-diameter) / 2);
        position: absolute;
        top: var(--button-toggle-offset);
        transform: translateX(var(--button-toggle-offset));
        box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
        transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
        background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
        box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
        display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
        width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}

@media screen and (min-width:768px) {

        .container_filters {
                width: 100%;
                display: flex;
                padding: 5px;
                gap: 5px;
                flex-wrap: nowrap;
        }

        .container_filters form {

                width: calc(100% - 30px);
                display: grid;
                grid-template-columns: 150px 15% 1fr 30px;
                gap: 10px;
                align-items: center;
        }

        .container_filters form span {
                width: 100%;
        }

        .container_filters form input,
        .container_filters form select {
                width: 100%;
        }

        .container_filters form input[type="search"] {
                width: 100%;
        }
}