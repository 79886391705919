.container_caps {
        display: flex;
        width: 100%;
        flex-direction: column;
}

.title {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 20px 0;
        gap: 10px;
}

.title>button {
        width: 100px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
}

.container_form_add {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
}

.container_table_caps {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        overflow-x: scroll;
}

.container_table_caps table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        min-width: 1000px;
}

.container_table_caps table thead tr {
        width: 100%;
}

.container_table_caps table thead tr th {
        width: 100%;
        font-size: 1em;
        font-weight: bold;
        color: #FFFFFF;
        padding: 5px;
        text-align: center;
        background-color: var(--primaryColor);
        border: 1px solid #FFFFFF;
}

.container_table_caps table tbody tr td {
        padding: 5px;
}

.container_table_caps table tbody tr {
        width: 100%;
}

.container_table_caps table tbody tr td:not(:nth-child(2)) {
        text-align: center;
}

.container_table_caps table tfoot {
        border-top: 1px solid var(--primaryColor);
}

.container_table_caps table tbody tr td:last-child button {
        width: 25px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;

}

.container_table_caps table tfoot tr td {
        font-size: 1.1em;
        font-weight: bold;
        color: var(--primaryColor);
        padding: 10px;
        text-align: center;
}

.container_table_caps table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

/* estilos de formulario de agregar tapas*/
.container_add_caps {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
}

.container_add_caps form {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        max-width: 500px;
        gap: 15px;
}

.container_add_caps form span {
        width: 100%;
        display: flex;
        flex-direction: column;
}

.container_add_caps form span select,
.container_add_caps form span input {
        width: 100%;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
}

.container_add_caps form span:last-child {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
}

.container_add_caps form span:last-child button {
        width: 100px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}


/*estilos de los filtros*/

.container_filters {
        width: 100%;
        display: flex;
        padding: 5px;
        gap: 5px;
        flex-wrap: wrap;
}


.container_filters form {
        display: grid;
        width: 100%;
        grid-template-columns: 18% 18% 150px 10% 1fr 30px 30px;
        gap: 10px;
}

.container_filters form span {
        display: flex;
        column-gap: 10px;
        align-items: center;
}

.container_filters form span input,
.container_filters form span select {
        width: 100%;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 10px;
}

.container_filters form button {
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
}

.switch {
        --button-width: 3.5em;
        --button-height: 27px;
        --toggle-diameter: 1.3em;
        --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
        --toggle-shadow-offset: 10px;
        --toggle-wider: 3em;
        --color-grey: #cccccc;
        --color-green: var(--primaryColor);
        display: flex;
        align-items: center;
        gap: 10px;
}

.slider {
        display: inline-block;
        width: var(--button-width);
        height: var(--button-height);
        background-color: var(--color-grey);
        border-radius: calc(var(--button-height) / 2);
        position: relative;
        transition: 0.3s all ease-in-out;
}

.slider::after {
        content: "";
        display: inline-block;
        width: var(--toggle-diameter);
        height: var(--toggle-diameter);
        background-color: #fff;
        border-radius: calc(var(--toggle-diameter) / 2);
        position: absolute;
        top: var(--button-toggle-offset);
        transform: translateX(var(--button-toggle-offset));
        box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
        transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
        background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
        box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
        display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
        width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}