.box_loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        column-gap: 10px;
}

.loader {
        border: 3px solid #FFFFFF;
        border-left-color: transparent;
        border-radius: 50%;
        border-left-color: transparent;
        width: auto;
        height: 70%;
        aspect-ratio: 1/1;
        animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
        0% {
                transform: rotate(0deg);
        }

        100% {
                transform: rotate(360deg);
        }
}