.container_details_seller {
        position: fixed;
        top: 50px;
        width: 600px;
        background-color: #FFFFFF;
        border-radius: 5px;
        height: calc(100vh - 60px);
        right: 20px;
        box-shadow: 1px 1px 5px #b7b7b7;
        border-radius: 10px;
        padding: 10px;
        overflow-y: scroll;
}

.container_details_seller>span {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
}

.container_details_seller>span button {
        display: flex;
        cursor: pointer;
        width: 25px;
        height: 25px;
        background-color: red;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        border: none;

}

.container_details_seller>span>button svg {
        cursor: pointer;
        fill: #FFFFFF;

}

.container_details_seller>span h3 {
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
}

.container_details_seller table {
        width: 100%;
        border-collapse: collapse;
}

.container_details_seller table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
}

.container_details_seller table thead tr {
        border: 1px solid #ccc;
}

.container_details_seller table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}


.container_details_seller table tbody tr td {
        padding: 10px;
}