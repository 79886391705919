.container_promotions {
        width: 100%;
        padding: 20px 0;

}

.title {
        display: flex;
        width: 100%;
        padding-bottom: 20px;
        text-align: left;
        gap: 10px;
}

.title > button{
        padding: 3px 20px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .2);
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;
}

.container_table_promotions {
        display: flex;
        width: 100%;
        height: auto;
        overflow-x: scroll;
}

.container_table_promotions table{
        width: 100%;
        min-width: 1000px;
}
.container_table_promotions table thead{        
        width: 100%;
        background-color: var(--primaryColor);
        color: #ffffff;

}
.container_table_promotions table thead tr,
.container_table_promotions table tbody tr{
        display: grid;
        padding: 10px;
        grid-template-columns: 50px 100px 15% 35% repeat(3,1fr);

}
.container_table_promotions table tbody tr td img{
        width: 50px;
        height: 50px;
        object-fit: contain;
}
.container_table_promotions table tbody tr td:last-child{
        display: flex;
        justify-content: center;
        gap: 5px;
}
.container_table_promotions table tbody tr td:last-child button{
        width: 35px;
        height: 35px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .2);
        cursor: pointer;

}
.container_table_promotions table tbody tr td:last-child button:first-child{
        background-color: var(--primaryColor);

}

.container_table_promotions table tbody tr td:last-child button svg{
        fill: #FFFFFF;
}

.container_table_promotions table tbody tr td:last-child button:nth-child(2){
        background-color: orange;

}

.container_table_promotions table tbody tr td:last-child button:last-child{
        background-color: red;

}

.container_table_promotions table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}