.container_title {
        display: flex;
        width: 100%;
        padding: 10px 0;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
}

.container_title>button {
        width: 100px;
        height: 30px;
        border: none;
        background-color: var(--primaryColor);
        font-weight: bold;
        color: white;
        border-radius: 5px;
}

.container_filters_buy_orders {
        display: flex;
        width: 100%;
        padding: 10px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
        gap: 10px;
        flex-wrap: wrap;
}

.container_filters_buy_orders form {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
}

.container_filters_buy_orders form div label {
        white-space: nowrap;
}


.container_filters_buy_orders form div span {
        display: flex;
        flex-wrap: wrap;
}

.container_filters_buy_orders form div:nth-last-child(2)>button {
        width: 100px;
        height: 25px;
        border: none;
        background-color: var(--primaryColor);
        font-weight: bold;
        color: white;
        border-radius: 5px;
        cursor: pointer;
}

.container_filters_buy_orders form div:last-child>button {
        width: 25px;
        height: 25px;
        border: none;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        cursor: pointer;

}

.box_filter_date {
        display: flex;
        width: auto;
        gap: 10px;
        align-items: center;
}

.box_filter_date span {
        display: flex;
        gap: 10px;
}

.box_filter_date span input {
        width: 160px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
}

.box_payment_status {
        width: auto;
        display: flex;
        gap: 10px;
        align-items: center;
        border-radius: 5px;
        flex-wrap: wrap;
}

.box_payment_status label {
        white-space: nowrap;
}

.box_payment_status select {
        width: 160px;
        height: 25px;
        border-radius: 5px;
}

.box_action_buy_order {
        display: flex;
        width: auto;
        height: auto;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;


}

.box_action_buy_order label {
        white-space: nowrap;
}

.box_action_buy_order select {
        width: 160px;
        height: 25px;
        border-radius: 5px;
}

.box_action_buy_order button {
        width: 100px;
        height: 25px;
        border-radius: 5px;
        cursor: pointer;
}

.active_state_orders {
        background-color: var(--primaryColor);
        color: white;
}