.container_add_promotion {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        background-color: var(--primaryBackground);
        max-width: 1400px;
        margin: 0 auto;
        gap: 20px;
}

.title {
        width: 100%;
        font-size: 1.2em;
        padding: 20px;
        text-align: center;
}

.container_form_add_promotion {
        display: flex;
        width: 50%;
        height: auto;
        flex-direction: column;
}

.container_add_promotion form {
        display: flex;
        width: 100%;
        height: auto;
        flex-wrap: wrap;

}

.container_add_promotion form label {
        width: 100%;
        height: auto;
        font-size: 1em;

}

.container_add_promotion form input,
.container_add_promotion form textarea {
        width: 100%;
        height: 30px;
        font-size: 1em;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .2);
}

.container_form_add_promotion form label[for='is_active'] {
        width: 110px;
}

.container_form_add_promotion form input[id='is_active'] {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
}

.container_add_promotion form textarea {
        max-width: 500px;
        min-width: 100%;
        min-height: 50px;
        max-height: 300px;
}

.products_of_promotions {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border: 1px solid rgba(0, 0, 0, .2);
}

.container_form_add_promotion  span:last-child{
        display: flex;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
}

.container_form_add_promotion  span:last-child > button{
        padding: 3px 30px;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 5px;
        font-size: 1em;
        font-weight: bold;
}

.products_of_promotions table {
        width: 100%;
        height: auto;
        max-width: 500px;
}

.products_of_promotions table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
}

.products_of_promotions table thead tr,
.products_of_promotions table tbody tr {
        display: grid;
        width: 100%;
        padding: 10px;
        grid-template-columns: 50% repeat(3, 1fr);
}

.products_of_promotions table tbody tr td:not(td:first-child) {
        text-align: center;
}

.products_of_promotions table tbody tr td button {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: none;
        background-color: red;
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;
}

.products_of_promotions table thead tr:nth-child(even) {
        background-color: #d8d8d8;
}

.products_of_promotions table caption {
        grid-column: 1 / -1;
        padding: 10px;
        font-weight: bold;
}

.container_products {
        display: flex;
        width: 50%;
        flex-direction: column;
        max-width: 500px;
        padding-bottom: 30px;
}

.box_product {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 20px;
}

.box_product span {
        width: 100%;
        height: auto;
        border: 1px solid rgba(0, 0, 0, .2);
        text-align: center;
        padding: 5px;
}

.box_product span img {
        width: 100%;
        height: auto;
        max-width: 100px;
        max-height: 100px;
        aspect-ratio: 1 / 1;
        object-fit: contain;
}

.box_product span p {
        text-align: left;
        padding-top: 10px;
        text-transform: lowercase;
}

.box_product span p:nth-child(2) {
        font-weight: bold;
}

.box_product span button {
        width: 100%;
        height: 20px;
        margin: 5px 0;
}