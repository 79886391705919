.container_add_manager {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
}

.container_add_manager>form {
        display: flex;
        width: 100%;
        max-width: 600px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
}

.container_add_manager>form>span {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

}

.box_add_account {
        position: fixed;
        width: 100%;
        height: calc(100vh - 40px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
        top: 40px;
        right: 0;
}

.box_add_account>div {
        max-width: 400px;
        width: 100%;
        max-width: 400px;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        flex-direction: column;
}

.box_add_account>div>form {
        display: flex;
        width: 100%;
        height: auto;
        gap: 10px;
        padding: 10px 0;
        background-color: #FFFFFF;
        flex-direction: column;
        padding: 20px;
}

.box_add_account>div>form>span {
        display: flex;
        width: 100%;
        height: auto;
        border: none;
        border-radius: 5px;
        gap: 10px;
        align-items: center;
}

.box_add_account>div>form>span>button {
        width: min-content;
        height: 30px;
        border: none;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border-radius: 5px;
        padding: 0 5px;
        cursor: pointer;
}

.box_add_account>div>form>span>input {
        height: 30px;
}


.box_add_account>div>div {
        width: 100%;
        height: auto;
        padding: 0 20px;
        overflow-y: auto;
}

.box_add_account>div>div>table {
        width: 100%;
        height: auto;
        border-collapse: collapse;

}

.box_add_account>div>div>table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        position: sticky;
        top: 0;
}

.box_add_account>div>div>table tbody tr td,
.box_add_account>div>div>table thead tr th {
        padding: 10px;
        text-align: center;
}

.box_add_account>div>div>table tbody tr td>button {
        width: auto;
        height: 20px;
        border: none;
        background-color: red;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 0 5px;
        cursor: pointer;
}

.box_add_subaccount {
        position: fixed;
        width: 100%;
        height: calc(100vh - 40px);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.5);
        top: 40px;
        flex-direction: column;
        right: 0;
}

.box_add_subaccount>div {
        max-width: 400px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        flex-direction: column;
}

.box_add_subaccount>div>form {
        display: flex;
        width: 100%;
        height: min-content;
        gap: 10px;
        padding: 10px 0;
        background-color: #FFFFFF;
        flex-direction: column;
        padding: 20px;
}

.box_add_subaccount>div>form>span {
        display: flex;
        width: 100%;
        height: auto;
        border: none;
        border-radius: 5px;
        gap: 10px;
        align-items: center;
}

.box_add_subaccount>div>form>span>button {
        width: min-content;
        height: 30px;
        border: none;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border-radius: 5px;
        padding: 0 5px;
}

.box_add_subaccount>div>div {
        width: 100%;
        height: auto;
        padding: 0 20px;
}

.box_add_subaccount>div>div>table {
        width: 100%;
        height: auto;
        border-collapse: collapse;
}

.box_add_subaccount>div>div>table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.box_add_subaccount>div>div>table tbody tr td,
.box_add_subaccount>div>div>table thead tr th {
        padding: 10px 0;
        text-align: center;
}

.box_add_subaccount>div>div>table tbody tr td>button {
        width: auto;
        height: 20px;
        border: none;
        background-color: red;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 0 5px;
        cursor: pointer;
}

.container_add_manager form>span:last-child {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
}

.container_add_manager form>span:last-child>button {
        white-space: nowrap;
        width: 100px;
        height: 30px;
        border: none;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border-radius: 5px;
        padding: 3px;
        font-weight: bold;
        cursor: pointer;
}

.container_add_manager form>span>h3 {

        padding: 10px 0;
}

.container_add_manager form>span label {
        display: flex;
        text-align: left;
        width: 100%;
        row-gap: 10px;
}

.container_add_manager form>span>span {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 10px;
}

.container_add_manager form>span>span>button {
        white-space: nowrap;
        height: 30px;
        border: none;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border-radius: 5px;
        padding: 3px;
}

.container_add_manager form>span select {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #ccc;
}

.container_add_manager form>span input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0 10px;
        outline: none;
}
