.container_update_worker {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        flex-direction: column;
}

.container_update_worker form {
        width: 100%;
        max-width: 500px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.container_update_worker span:first-child>h3 {
        font-size: 1.2em;
        font-weight: bold;
        margin: 20px;
}

.container_update_worker form input {
        width: 100%;
        height: 35px;
        margin: 10px 0;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 10px;
}

.container_update_worker form label {
        position: relative;
        width: 100%;
        text-align: left;
}

.container_update_worker form>span:nth-last-child(2) {
        width: 100%;
        display: flex;
        padding: 5px 0 40px 0;
        flex-direction: column;

}

.container_update_worker form>span:nth-last-child(2) b {
        color: red;
}

.container_update_worker form span {
        width: 100%;
        display: flex;
        place-content: center;
        gap: 20px;
}

.container_update_worker form span button {
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
}

.container_update_worker form span button:first-child {
        background-color: green;
        color: #fff;
}

.container_update_worker form span button:last-child {
        background-color: red;
        color: #fff;
}

.container_filters {
        width: 100%;
        display: flex;
        padding: 5px;
        gap: 5px;
        flex-wrap: wrap;
}


.container_filters>button[type="button"] {
        width: 30px;
        height: 30px;
        aspect-ratio: 1 / 1;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
}

.container_filters form {

        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
}

.container_filters form span {
        width: calc(50% - 10px);
}

.container_filters form input,
.container_filters form select {
        width: 50%;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 10px;
}

.container_filters form input[type="search"] {
        width: calc(100% - 40px);
}

.container_filters form button {
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
}

.switch {
        --button-width: 3.5em;
        --button-height: 27px;
        --toggle-diameter: 1.3em;
        --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
        --toggle-shadow-offset: 10px;
        --toggle-wider: 3em;
        --color-grey: #cccccc;
        --color-green: var(--primaryColor);
        display: flex;
        align-items: center;
        gap: 10px;
}

.slider {
        display: inline-block;
        width: var(--button-width);
        height: var(--button-height);
        background-color: var(--color-grey);
        border-radius: calc(var(--button-height) / 2);
        position: relative;
        transition: 0.3s all ease-in-out;
}

.slider::after {
        content: "";
        display: inline-block;
        width: var(--toggle-diameter);
        height: var(--toggle-diameter);
        background-color: #fff;
        border-radius: calc(var(--toggle-diameter) / 2);
        position: absolute;
        top: var(--button-toggle-offset);
        transform: translateX(var(--button-toggle-offset));
        box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
        transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
        background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
        box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
        display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
        width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}

@media screen and (min-width:768px) {

        .container_filters {
                width: 100%;
                display: flex;
                padding: 5px;
                gap: 5px;
                flex-wrap: nowrap;
        }

        .container_filters form {

                width: calc(100% - 30px);
                display: grid;
                grid-template-columns: 150px 15% 1fr 30px;
                gap: 10px;
                align-items: center;
        }

        .container_filters form span {
                width: 100%;
        }

        .container_filters form input,
        .container_filters form select {
                width: 100%;
        }

        .container_filters form input[type="search"] {
                width: 100%;
        }
}