.container_table_params {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
}

.box_title_and_button_new {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
}

.box_title_and_button_new>span {
        display: flex;
        gap: 10px;
}

.box_title_and_button_new>span>button {
        width: auto;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        cursor: pointer;
        padding: 0 20px;
}

.container_table_params table {
        width: 100%;
        border-collapse: collapse;
}

.container_table_params table thead tr th {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
}

.container_table_params table thead {
        background-color: var(--primaryColor);
}

.container_table_params table tbody tr td {
        padding: 5px 10px;
}

.container_table_params table tbody tr td:last-child {
        display: flex;
        gap: 10px;
}

.container_table_params table tbody tr td>button {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: var(--primaryColor);
        border: none;
        cursor: pointer;
}

.container_table_params table tbody tr td>button:last-child {
        background-color: red;
}

.container_table_params table tbody tr td>button>svg {
        fill: #FFFFFF;
}

.container_table_params table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_add_param {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
}

.container_add_param>h3 {
        font-size: 1.2rem;
        padding: 20px;
        font-weight: bold;
}

.container_add_param form {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
}

.container_add_param>form>div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
}

.container_add_param>form>div select,
.container_add_param>form>div input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .6);
}

.container_add_param>form>div textarea {
        width: 100%;
        height: max-content;
        max-height: 60px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .6);
        resize: none;
        padding: 5px;
}

.container_add_param>form>div:nth-child(8)>span {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
}

.container_add_param>form>div:nth-child(8)>span>input {
        max-width: calc(100% / 7 - 5px) !important;
}

.container_add_param>form>div:last-child {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
}

.container_add_param>form>div:last-child>button {
        width: 100px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        cursor: pointer;
}

.container_add_param>form>div:last-child>button:last-child {
        background-color: red;
}

.box_intercalate_buttons {
        width: 100%;
        display: flex;
        padding: 10px;
        gap: 10px;
        border: 1px solid rgba(0, 0, 0, .6);
        border-radius: 5px;
}

.box_intercalate_buttons>button {
        width: auto;
        height: 30px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 0 20px;
        border: 1px solid var(--primaryColor);
}

.active_button_page {
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_table_maintenance {
        width: 100%;
        display: flex;

}


.container_table_maintenance table {
        width: 100%;
        border-collapse: collapse;
}

.container_table_maintenance table thead tr th {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
}

.container_table_maintenance table thead {
        background-color: var(--primaryColor);
}

.container_table_maintenance table thead tr th {
        padding: 5px 10px;
}

.container_table_maintenance table tbody tr td {
        padding: 5px 10px;
}
.container_table_maintenance table tbody tr td:last-child{
        display: flex;
        gap: 10px;
}
.container_table_maintenance table tbody tr td:last-child button:first-child{
        background-color: var(--primaryColor);
}
.container_table_maintenance table tbody tr td>button {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: red;
        border: none;
        cursor: pointer;

}

.container_table_maintenance table tbody tr td>button>svg {
        fill: #FFFFFF;
}


.container_table_maintenance table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.form_add_maintenance {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px 0;
}

.form_add_maintenance h3 {
        font-size: 1.2rem;
        padding: 20px;
        font-weight: bold;
}

.form_add_maintenance form {
        display: flex;
        width: 100%;
        max-width: 600px;
        flex-direction: column;
}

.form_add_maintenance form div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
}

.form_add_maintenance form div select,
.form_add_maintenance form div input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .6);
}

.form_add_maintenance form div textarea {
        min-width: 100%;
        min-height: 50px;
        max-height: 100px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .6);
        resize: none;
}

.form_add_maintenance form div:last-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 20px;
}

.form_add_maintenance form div:last-child button {
        width: 100px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: var(--primaryColor);
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
}

.form_add_maintenance form div:last-child button:last-child {
        background-color: red;
}

.container_filter {
        width: 100%;
}

.container_filter>form {
        width: 100%;
        display: flex;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, .6);
        margin-top: 10px;
        border-radius: 5px;
        gap: 10px;
        flex-wrap: wrap;
}

.container_filter>form span {
        display: flex;
        align-items: center;
        gap: 10px;
}

.container_filter>form>span input,
.container_filter>form>span select {
        width: auto;
        height: 30px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .6);
}

.container_filter>form>span button {
        width: auto;
        height: 30px;
        border-radius: 5px;
        border: none;
        background-color: var(--primaryColor);
        cursor: pointer;
        padding: 0 20px;
        color: #FFFFFF;
        font-weight: bold;
}
