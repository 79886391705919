.box_back {
        width: 100%;
        padding: 10px 0;
}

.box_back>button {
        width: 100px;
        height: 25px;

}

.box_details_order {
        width: 100%;
        padding: 10px 0;

}

.box_details_order>h3 {
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        padding-left: 10px;
        padding-bottom: 10px;
}

.box_details_order>table {
        display: flex;
        width: 100%;
        padding: 10px 0;
        box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.4);
}

.box_details_order>table thead {
        width: 25%;
        padding: 10px;
}

.box_details_order>table thead tr,
.box_details_order>table tbody tr {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: left;
        row-gap: 10px;
        justify-content: center;
}

.box_details_order>table tbody {
        width: 75%;
        padding: 10px 0;

}

.box_details_order>table tr td form {
        display: flex;
        column-gap: 10px;
}

.box_details_order>table tr td form select {
        height: 25px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
}

.box_details_order>table tr td:nth-last-child(2) {
        display: flex;
        gap: 10px;
}

.box_details_order>table tr td:nth-last-child(2) button {
        width: 100px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
        background-color: green;
        color: #FFFFFF;
        font-weight: bold;
}

.box_details_order>table tr td:nth-last-child(2) button:last-child {
        background-color: red;
}

.box_details_order>table tr td:nth-last-child(2) button:last-child a {
        text-decoration: none;
        color: #FFFFFF;

}

.box_details_order>table tr td form button {
        width: 100px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
        background-color: var(--primaryColor);
        font-weight: bold;
        color: #FFFFFF;
}

.box_detail_product_order {
        display: flex;
        width: 100%;
        padding: 10px 0;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
}


.box_detail_product_order>h3 {
        width: auto;
        text-align: left;
        text-transform: uppercase;
        padding-left: 10px;
}

.box_detail_product_order>button {
        padding: 3px 20px;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
}

.box_detail_product_order>div {
        position: relative;
        width: 100%;
        overflow-x: scroll;
}

.box_detail_product_order>div table {
        width: 100%;
        min-width: 800px;
        padding: 10px 0;
}

.box_detail_product_order>div table thead tr,
.box_detail_product_order>div table tbody tr {
        display: grid;
        width: 100%;
        grid-template-columns: 50px 300px repeat(2, 1fr) 200px;
        padding: 5px;
}

.box_detail_product_order>div table tfoot tr {
        display: grid;
        width: 100%;
        grid-template-columns: calc(100% - 350px) 350px;
        padding: 10px;
        font-size: 1.1em;
        font-weight: bold;

}

.box_detail_product_order>div table {
        border: 1px solid rgb(0, 0, 0, 0.3);
        padding-top: 0;
}

.box_detail_product_order table tbody tr td:nth-last-child(2) {
        display: flex;
        row-gap: 5px;
        flex-direction: column;
}

.box_detail_product_order table tbody tr td:nth-last-child(2) p {
        padding: 3px 20px;
        width: min-content;
        height: min-content;
        white-space: nowrap;
        border-radius: 5px;
        font-weight: bold;
        color: #FFFFFF;
}

.box_detail_product_order>div table thead tr {
        background-color: var(--primaryColor);
        padding: 10px;
        color: #FFFFFF;
        text-align: center;
}

.box_detail_product_order>div table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.box_detail_product_order>div table tbody tr td {
        text-align: center;
}

.box_detail_product_order > div table tbody tr td:last-child {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
}

.box_detail_product_order>div table tbody tr td:last-child button {
        height: auto;
        width: min-content;
        padding: 3px 20px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
        background-color: var(--primaryColor);
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
        white-space: nowrap;
}

.box_detail_product_order>div table tbody tr td:last-child button:last-child {
        background-color: red;
}