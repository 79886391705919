.container_filters {
        display: flex;
        width: 100%;
}

.container_filters>span {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px 0;
        align-items: center;
}

.container_filters>span>button {
        padding: 2px 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-size: 1.1em;

}

.container_table_store {
        width: 100%;
        overflow-x: scroll;
}

.container_table_store table {
        width: 100%;
        border-collapse: collapse;
        min-width: 1100px;
}

.container_table_store table thead {
        background: var(--primaryColor);
        color: #FFFFFF;
}

.container_table_store table thead tr th {
        padding: 5px 10px;
        border: 1px solid #FFFFFF;
}

.container_table_store table tbody tr td {
        font-size: 1em;
        padding: 5px 10px;

}

.container_table_store table tbody tr td:nth-child(7) button {
        padding: 3px 20px;
        background-color: var(--primaryColor);
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: #FFFFFF;
}

.container_table_store table tbody tr td:last-child button {
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        border: none;
        align-items: center;
        justify-content: center;
        background-color: red;
        cursor: pointer;
}

.container_table_store table tbody tr td:last-child button svg {
        fill: #FFFFFF;
}


.container_table_store table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_table_store table tbody tr:hover {
        box-shadow: 0px 0px 5px 0px #858585;
        font-weight: bold;
}

.container_add_package_store {
        width: 100%;
        display: flex;
        justify-content: center;
}

.container_add_package_store form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 500px;
}

.container_add_package_store form>h3 {
        padding: 20px;
        text-align: center;
}

.container_add_package_store form span {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0;
        justify-content: center;

}

.container_add_package_store form span select,
.container_add_package_store form span input {
        width: 100%;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
}

.container_add_package_store form span:nth-last-child(2) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: initial;
}

.container_add_package_store form span:nth-last-child(2) label {
        width: min-content;
}

.container_add_package_store form span:nth-last-child(2) input[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
}

.container_add_package_store form span:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
}

.container_add_package_store form span:last-child>button {
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-size: 1.1em;
}

.container_add_arrival {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        align-items: center;

}

.container_add_arrival>h3 {
        text-align: center;
        padding: 20px;
}

.container_add_arrival div:nth-child(2) {
        width: 100%;
        max-width: 500px;
        padding-bottom: 20px;
}

.container_add_arrival div:nth-child(2) ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
}

.container_add_arrival div:nth-child(2) ul li {
        width: 100%;
        display: flex;

}

.container_add_arrival div:nth-child(2) ul li b {
        width: 50%;
}

.container_add_arrival div:last-child {
        width: 100%;
        max-width: 500px;
}

.container_add_arrival div:last-child form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
}

.container_add_arrival div:last-child form span {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
}

.container_add_arrival div:last-child form span:first-child input {
        width: 100%;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
}

.container_add_arrival div:last-child form span:nth-child(2) {
        flex-direction: row;
}

.container_add_arrival div:last-child form span:nth-child(2) label {
        width: min-content;
}

.container_add_arrival div:last-child form span:nth-child(2) input {
        width: 20px;
        height: 20px;
        cursor: pointer;
}

.container_add_arrival div:last-child form span:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
}

.container_add_arrival div:last-child form span:last-child button {
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-size: 1.1em;
}

.container_add_arrival div:last-child form span:last-child button:last-child {
        background-color: red;
}