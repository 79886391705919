.container_add_client {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        align-items: center;
}

.container_add_client h3 {
        font-size: 1.4em;
        font-weight: bold;
        padding: 20px 0;
}

.container_add_client form {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 500px;
        flex-direction: column;
        gap: 10px;
}

.container_add_client form input,
.container_add_client form select {
        width: 100%;
        height: 35px;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        outline: var(--primaryColor);
        font-size: 1em;
        color: #000000;
}

.container_add_client form span:last-child {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 20px;
        padding: 20px;
}

.container_add_client form span:last-child button {
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        background-color: green;
        color: #FFFFFF;
}

.container_add_client form span:last-child button:last-child {
        background-color: red;
}