.container_control {
        width: 100%;
        overflow-x: scroll;
}

.container_control table {
        width: 100%;
        min-width: 1700px;
        border-collapse: collapse;
}

.container_control table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        position: sticky;
        top: 0;
}

.container_control table thead tr th {
        padding: 5px 10px;
        text-align: center;
        font-weight: 500;
        font-weight: bold;
        font-size: 1em;
        border: 1px solid #FFFFFF;
}

.container_control table tfoot tr td {
        padding: 5px 10px;
        font-weight: 500;
        font-weight: bold;
        font-size: 0.9em;
        border-top: 1px solid var(--primaryColor);
}

.container_control table tbody tr td button {
        padding: 2px 10px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_control table tbody tr td {
        padding: 5px 10px;
        font-size: 1em;
        overflow: hidden;
}

.container_control table tbody tr td:last-child button {
        background-color: red;
        width: 30px;
        height: 30px;
        border-radius: 5px;
}

.container_control table tbody tr td:last-child button svg {
        fill: #FFFFFF;
}

.container_control table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_control table tbody tr:hover {
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.7);
        transition: box-shadow 0.2s ease-in-out;
        font-weight: bold;
        transform: scale(1.01);

}

.container_filters_control {
        width: 100%;
        display: flex;
        padding: 10px 0;
}

.container_filters_control form {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
        gap: 5px;
        grid-template-areas:
                "."
                "."
                "."
                "."
                "search";
}

.container_filters_control form>span:last-child {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto auto;
        grid-template-areas:
                "span1 span1"
                ". ."
                ". .";
        gap: 4px;
        grid-area: search;
}

.container_filters_control form>span:last-child span:first-child {
        grid-area: span1
}

.container_filters_control form span {
        width: 100%;
        display: flex;
        column-gap: 5px;
        align-items: center;
}

.container_filters_control form span label {
        white-space: nowrap;
}

.container_filters_control form span select,
.container_filters_control form span input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #d8d8d8;
}

.container_filters_control form span:last-child span:nth-last-child(2) button {
        width: 100%;
        height: 30px;
        padding: 0 20px;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_filters_control form span:last-child button {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #969696;
        cursor: pointer;
}

.container_exit_control {
        width: 100%;
        display: flex;
        justify-content: center;
}

.container_exit_control form {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        gap: 10px;
}

.container_exit_control form h3 {
        text-align: center;
        font-weight: bold;
        padding: 10px 0px;
}

.container_exit_control form span {
        display: flex;
        flex-direction: column;
        gap: 5px;
}

.container_exit_control form span select,
.container_exit_control form span input {
        border-radius: 5px;
        height: 30px;
        border: 1px solid #d8d8d8;
}

.container_exit_control form span:last-child {
        justify-content: center;
        align-items: center;
        gap: 10px;
}

.container_exit_control form span:last-child button {
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_arrival_control {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px 0;
}

.container_arrival_control form {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        gap: 10px;
        padding: 10px 0px;
}

.container_arrival_control form h3 {
        text-align: center;
        font-weight: bold;
        padding: 10px 0px;
}

.container_arrival_control form span {
        display: flex;
        flex-direction: column;
        gap: 5px;
}

.container_arrival_control form span select,
.container_arrival_control form span input {
        border-radius: 5px;
        height: 30px;
        border: 1px solid #d8d8d8;
}

.container_arrival_control form span:last-child {
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: initial;
}

.container_arrival_control form span:last-child button {
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_arrival_control form span:last-child button:last-child {
        background-color: red;
}

.container_sales_control {
        width: 100%;
        display: flex;
        justify-content: center;
}

.container_sales_control form {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        gap: 10px;
        padding: 10px 0px;
}

.container_sales_control form h3 {
        text-align: center;
        font-weight: bold;
        padding: 10px 0px;
}

.container_sales_control form span {
        display: flex;
        flex-direction: column;
        gap: 5px;
}

.container_sales_control form span input {
        border-radius: 5px;
        height: 30px;
        border: 1px solid #d8d8d8;
}

.container_sales_control form span:last-child {
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-direction: initial;
}

.container_sales_control form span:last-child button {
        width: 100px;
        height: 30px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_sales_control form span:last-child button:last-child {
        background-color: red;
}

@media screen and (min-width:768px) {

        .container_filters_control form {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: auto auto;
                gap: 5px;
                grid-template-areas:
                        ". . . ."
                        "search search search search";
        }

        .container_filters_control form>span:last-child {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 100px 30px;
                grid-template-rows: auto;
                grid-template-areas:
                        ". . . .";
                grid-area: search;
        }

        .container_filters_control form>span:last-child span:first-child {
                grid-area: initial
        }

}