.container_list_order {
        position: relative;
        display: flex;
        width: 100%;
        margin-top: 10px;
        overflow-x: scroll;
}

.container_list_order table {
        width: 100%;
        min-width: 1400px;
}

.container_list_order table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        position: sticky;
        top: 0;
}

.container_list_order table thead tr,
.container_list_order table tbody tr {
        display: grid;
        width: 100%;
        grid-template-columns: 50px 15% 20% repeat(3, 1fr) 15% 15% 100px;
        padding: 5px 20px;
}

.container_list_order table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_list_order table tbody tr td:nth-last-child(2){
        display: flex;
        flex-direction: column;
        row-gap: 5px;
}
.container_list_order table tbody tr td:nth-last-child(2) p {
        display: flex;
        padding: 2px 5px;
        flex-direction: column;
        width: min-content;
        white-space: nowrap;
        font-weight: bold;
        border-radius:5px ;
        color: #FFFFFF;
}
.container_list_order table tbody tr td:last-child {
        display: flex;
        gap: 10px;
}

.container_list_order table tbody tr td button {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.3);
        cursor: pointer;
}

.container_list_order table tbody tr button svg {
        fill: #FFFFFF;
}