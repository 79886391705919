.container_table_deliveries {
        width: 100%;
        overflow-x: scroll;
}

.container_table_deliveries table {
        width: 100%;
        min-width: 900px;
}

.container_table_deliveries table thead tr,
.container_table_deliveries table tbody tr {
        display: grid;
        width: 100%;
        grid-template-columns: 50px repeat(8, 1fr);
        padding: 5px 20px;
}

.container_table_deliveries table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_table_deliveries table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_table_deliveries table tbody tr td:nth-last-child(2) {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
}

.container_table_deliveries table tbody tr td:nth-last-child(2) p {
        display: flex;
        padding: 2px 5px;
        flex-direction: column;
        width: min-content;
        white-space: nowrap;
        font-weight: bold;
        border-radius: 5px;
        color: #FFFFFF;
}

.container_table_deliveries table tbody tr td:last-child {
        text-align: center;

}

.container_table_deliveries table tbody tr td button {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: green;
        border: 1px solid rgba(0, 0, 0, 0.3);
        cursor: pointer;
}

.container_table_deliveries table tbody tr td button svg {
        fill: #FFFFFF;
}