.container_table_list_products {
        width: 100%;
        display: flex;
        justify-content: center;
        overflow-x: scroll;
}

.container_table_list_products table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
}

.container_table_list_products table thead {
        background-color: var(--primaryColor);
}

.container_table_list_products table thead tr th {
        padding: 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid #fff;
}

.container_table_list_products table tbody tr td {
        padding: 10px;
        text-align: center;
        font-size: 14px;
}

.container_table_list_products table tbody tr:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        font-weight: bold;
}

.container_table_list_products table tbody tr td img {
        width: 50px;
        height: 50px;
        object-fit: contain;
}

.container_table_list_products table tbody tr td:nth-last-child(2) p {
        color: #FFFFFF;
        padding: 3px 5px;
        border-radius: 5px;
}

.container_table_list_products table tbody tr td:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
}

.container_table_list_products table tbody tr td:last-child button {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        border: none;
        background-color: var(--primaryColor);
}

.container_table_list_products table tbody tr td:last-child button:last-child {
        background-color: red;
}

.container_table_list_products table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}


.container_table_list_products table tbody tr td:last-child button svg {
        fill: #FFFFFF;
}