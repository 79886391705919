.container_shipping {
        display: flex;
        width: 100%;
        flex-direction: column;
}
.box_title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
}
.box_title strong{
        font-size: 1.2em;
        color: var(--primaryColor);
}
.box_table_shipping {
        width: 100%;
        overflow-x: scroll;
}

.box_table_shipping table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        min-width: 700px;
}

.box_table_shipping table thead tr th {
        padding: 10px;
        text-align: center;
        border: 1px solid #ddd;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.box_table_shipping table tbody tr td {
        padding: 10px;
        text-align: left;
}

.box_table_shipping table tfoot tr td {
        padding: 10px;
        text-align: left;
        border-top: 1px solid var(--primaryColor);
        background-color: #f1f1f1;
        color: #000000;
        font-weight: bold;
}

.box_table_shipping table tbody tr:hover {
        box-shadow: 1px 1px 5px #9c9c9c;
}

.box_table_shipping table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

/* component deliveryStatus */

.delivery_status {
        display: flex;
        position: relative;
        padding: 2px 5px;
        border-radius: 3px;
        font-size: 0.9em;
        word-wrap: nowrap;
        color: #FFFFFF;
        margin-bottom: 4px;
}