.container_add_order {
        width: 100%;
}

.container_add_order>h3 {
        padding-top: 10px;
}

.box_search_client {
        display: flex;
        padding: 10px;
        border: 1px solid #ccc;
}

.box_search_client>button {
        display: grid;
        width: 150px;
        height: 30px;
        place-content: center;
}

.box_search_client>form {
        display: flex;
        width: calc(100% - 150px);
}

.box_search_client>form>input {
        width: calc(100% - 40px);
        height: 30px;
        padding: 0 10px;
        border: 1px solid #ccc;
        border-right: none;
}

.box_search_client>form>button {
        width: 30px;
        height: 30px;
        border: 1px solid #ccc;
        border-left: none;
        background-color: #ccc;
}

.box_search_client>form>button svg {
        width: 1.3em;
        height: 1.3em;
}

.customers_found_table {
        width: 100%;
        margin-top: 10px;
        overflow-x: scroll;
}

.customers_found_table>table {
        width: 100%;
        min-width: 1000px;
}

.customers_found_table>table thead tr,
.customers_found_table>table tbody tr {
        display: grid;
        width: 100%;
        grid-template-columns: 10% 10% 20% 10% 20% 20% 10%;
        padding: 5px 20px;
}


.customers_found_table>table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.customers_found_table>table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.customers_found_table table tbody tr td:last-child button {
        padding: 3px 20px;
        border-radius: 5px;
        background-color: green;
        border: 1px solid rgba(0, 0, 0, 0.3);
        cursor: pointer;
        color: #FFFFFF;
}


.box_details_order {
        width: 100%;
        padding: 10px 0;

}


.box_details_order>form table {
        display: flex;
        width: 100%;
        padding: 10px 0;
        box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.4);
}

.box_details_order>form table thead {
        width: 25%;
        padding: 10px;
}

.box_details_order>form table thead tr,
.box_details_order>form table tbody tr {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: left;
        row-gap: 10px;
        justify-content: center;
}

.box_details_order>form table thead tr th {
        padding: 1px 0;
}

.box_details_order>form table tbody {
        width: 75%;
        padding: 10px 0;

}

.box_details_order>form table tr td form {
        display: flex;
        column-gap: 10px;
}
.box_details_order>form table tbody tr td input[type='checkbox']{
        width: 20px;
        height: 20px;
}
.box_details_order>form table tr td form select {
        height: 25px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
}

.box_details_order>form table tr td:nth-last-child(2) {
        display: flex;
        gap: 10px;
}

.box_details_order>form table tr td:nth-last-child(2) button {
        width: 100px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
        background-color: green;
        color: #FFFFFF;
        font-weight: bold;
}

.box_details_order>form table tr td:nth-last-child(2) button:last-child {
        background-color: red;
}

.box_details_order>form table tr td:nth-last-child(2) button:last-child a {
        text-decoration: none;
        color: #FFFFFF;

}

.box_details_order>form table tr td form button {
        width: 100px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid rgb(0, 0, 0, 0.3);
        background-color: var(--primaryColor);
        font-weight: bold;
        color: #FFFFFF;
}

.container_products {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 20px;
        margin-bottom: 50px;
}

.container_products div {
        display: flex;
        padding: 10px;
        flex-direction: column;
        background-color: #FFFFFF;
        justify-content: space-between;
        border: 1px solid #ccc;
}

.container_products div span:first-child {
        width: 100%;
        display: flex;
        max-width: 150px;
        aspect-ratio: 1 / 1;
        justify-content: center;
}


.container_products div span:last-child {
        display: grid;
        width: 100%;
}

.container_products div p:nth-child(2) {
        font-size: .8em;
        padding: 3px 0;
}

.container_products div p:nth-child(3) {
        font-size: 1.1em;
        font-weight: bold;
        padding: 3px 0;
}

.container_products div span:last-child button {
        position: relative;
        display: flex;
        min-width: 100%;
        height: 25px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1em;
        cursor: pointer;
}

.container_products div span:first-child img {
        height: 100%;
        object-fit: contain;
}

.container_product_associated {
        display: flex;
        width: 100%;
        gap: 20px;
        padding: 10px 0;
        overflow-x: scroll;
        flex-wrap: wrap;
}

.container_product_associated>table {
        width: 100%;
        min-width: 800px;

}

.container_product_associated>table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_product_associated>table thead tr,
.container_product_associated>table tbody tr,
.container_product_associated>table tfoot tr {
        width: 100%;
        display: grid;
        grid-template-columns: 10% 40% 10% 20% 20%;
        padding: 5px 20px;
}

.container_product_associated>table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}