.container_production {
        width: 100%;

}

.container_title {
        width: 100%;
        padding: 10px 0;
        display: flex;
        gap: 20px;
        align-items: center;
}

.container_title p {
        font-size: 1.2em;
        font-weight: 600;
        color: #000;
}

.container_title>button {
        font-size: 1.2em;
        font-weight: 600;
        color: #000;
        padding: 3px 20px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_table {
        width: 100%;
        overflow-x: scroll;
}

.container_table table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
}

.container_table table thead tr th {
        font-size: 1em;
        font-weight: bold;
        color: #FFFFFF;
        padding: 5px 0;
        text-align: center;
        background-color: var(--primaryColor);
        border: 1px solid #FFFFFF;
}

.container_table table tbody tr {
        overflow: hidden;
        width: 100%;
}

.container_table table tbody tr td {
        padding: 5px;
        font-size: 1em;
}

.container_table table tbody tr:hover {
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.7);
        transition: box-shadow 0.2s ease-in-out;
        font-weight: bold;
}

.container_table table tbody tr td:not(:nth-child(2)) {
        text-align: center;
}

.container_table table tfoot {
        border-top: 1px solid var(--primaryColor);
}

.container_table table tbody tr td:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
}

.container_table table tbody tr td:last-child button {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: red;
        cursor: pointer;
}

.container_table table tbody tr td:last-child button:first-child {
        background-color: var(--primaryColor);

}

.container_table table tbody tr td:last-child button svg {
        fill: #FFFFFF;
}

.container_table table tfoot tr td {
        font-size: 1.1em;
        font-weight: bold;
        color: var(--primaryColor);
        padding: 10px;
        text-align: center;
}

.container_table table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

/* estilos de la pagina para agregar un nuevo turno */

.container_add_turn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
}

.container_add_turn form {
        display: flex;
        width: 100%;
        max-width: 500px;
        gap: 10px;
        flex-direction: column;
        padding: 20px 0;
        align-items: center;
}

.container_add_turn form h3 {
        font-size: 1.2em;
        font-weight: 600;
        color: #000;
        text-align: center;
}

.container_add_turn form span {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        flex-direction: column;
}

.container_add_turn form span p {
        width: 100%;
        text-align: right;
        font-size: .9em;
        color: red;
}

.container_add_turn form span input {
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;

}

.container_add_turn form>span:last-child {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
}

.container_add_turn span:last-child button {
        width: 20%;
        min-width: 100px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-size: 1.1em;
        font-weight: 600;
        cursor: pointer;
}

/*estilos de los filtros*/

.container_filters {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 0;
        flex-direction: column;

}

.box_filter {
        display: flex;
        width: 100%;
        flex-direction: column;
}

.box_filter>h3 {
        font-size: 1.2em;
        font-weight: 600;
        color: #000;
        padding-bottom: 5px;
}

.box_filter>span {
        width: 100%;
        display: flex;
        gap: 10px;
        padding: 5px 0;
        flex-wrap: wrap;
}

.box_filter>span form {
        width: 100%;
        display: flex;
        text-align: center;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
}

.box_filter>span form input,
.box_filter>span form select {
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        width: 40%;
}

.box_filter>span form button[type='submit'] {
        width: calc(60% - 20px);
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-size: 1.1em;
        font-weight: 600;
        cursor: pointer;
}

.box_filter>span button[type='button'] {
        width: 30px;
        height: 30px;
        border: 1px solid grey;
        border-radius: 5px;
        background-color: grey;
        font-size: 1.1em;
        font-weight: 600;
        cursor: pointer;
}

.box_filter>span button[type='button'] svg {
        fill: #FFFFFF;
}

.box_information_table {
        display: flex;
        width: 100%;
        padding-top: 5px;
        gap: 5px;
}


@media screen and (min-width:768px) {
        .box_filter>span {
                flex-wrap: nowrap;
        }

        .box_filter>span form {
                display: grid;
                width: calc(100% - 30px);
                grid-template-columns: 25% 5% 25% 30% 1fr;
        }

        .box_filter>span form input,
        .box_filter>span form select {
                width: 100%;
        }

        .box_filter>span form button[type='submit'] {
                width: 100%;
        }


}