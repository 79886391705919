.box_title_daily {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        gap: 20px;
}

.box_title_daily>button {
        padding: 3px 20px;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        cursor: pointer;
}

.container_table_daily {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        overflow: auto;

}


.container_table_daily table {
        position: relative;
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
}

.container_table_daily table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        position: sticky;
        top: 0;
}

.container_table_daily table tbody tr td,
.container_table_daily table thead tr th {
        padding: 5px 20px;
        border: 1px solid #484848cc;
}

.container_table_daily table tbody tr td:last-child {
        text-align: center;
}

.container_table_daily table tbody tr td button {
        padding: 3px 10px;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        margin: 0 5px;
        border: none;
        cursor: pointer;

}

.container_table_daily table tbody tr td button:last-child {
        background-color: red;
}

.container_filter_daily {
        width: 100%;
        display: flex;
        padding: 5px;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
}

.container_filter_daily>span {
        display: flex;
        gap: 10px;
        align-items: center;
}

.container_filter_daily>span select,
.container_filter_daily>span input {
        padding: 2px 10px;
        border-radius: 5px;
        border: 1px solid #484848cc;
}

.container_filter_daily>span label {
        white-space: nowrap;
        font-size: .9em;
}

.container_filter_daily>span button {
        padding: 4px 10px;
        border-radius: 5px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        border: none;
        cursor: pointer;
}

.container_filter_daily>span button {
        fill: #FFFFFF;
}

.container_total_daily {
        width: 100%;
        height: auto;
        overflow-x: scroll;
        padding: 20px 0;
}

.container_total_daily table {
        width: 100%;
        border-collapse: collapse;
}

.container_total_daily>table>thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
        text-align: center;
}

.container_total_daily>table>thead tr th{
        padding: 10px;
        border: 1px solid #FFFFFF;
}

.container_total_daily>table>tbody{
        background-color: #d5d5d5cc;
        font-weight: bold;
        text-align: center;
}