.container_title {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        text-transform: uppercase;
}

.container_title>span {
        display: flex;
        gap: 10px;
        font-weight: bold;
}

.container_title>span button[type="button"] {
        font-size: .9em;
        border: none;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        padding: 5px 10px;
        cursor: pointer;
}

.container_surrenders {
        width: 100%;
        height: auto;
        padding: 10px 0;
        overflow-x: auto;
}

.container_surrenders>table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
}

.container_surrenders>table thead {
        width: 100%;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_surrenders>table thead tr th {
        padding: 4px 10px;
        border: 1px solid #FFFFFF;

}

.container_surrenders>table tbody {
        width: 100%;
        background-color: #FFFFFF;
        color: #000000;
}

.container_surrenders>table tbody tr td {
        padding: 5px 10px;
}

.container_surrenders>table tfoot {
        border-top: 1px solid #000000;
}

.container_surrenders>table tfoot tr td {
        padding: 5px 10px;
        font-size: 1.1em;
        font-weight: bold;

}

.container_surrenders>table tbody tr td:last-child {
        display: flex;
        gap: 10px;
}

.container_surrenders>table tbody tr td:last-child>button {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 3px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;
}

.container_surrenders>table tbody tr td:last-child>button>svg {
        fill: #FFFFFF;
}


.container_surrenders>table tbody tr td:last-child>button:last-child {
        background-color: #FF0000;
}

.container_surrenders table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.box_cash_control {
        display: flex;
        width: 100%;
        height: auto;
        gap: 10px;
        padding: 10px 0;
        flex-wrap: wrap;
}

.box_cash_control button {
        width: 20%;
        min-width: 200px;
        height: 30px;
        border: none;
        font-weight: bold;
        border: 1px solid var(--primaryColor);
        color: var(--primaryColor);
}

.container_quadrature {
        display: flex;
        width: 100%;
        height: auto;
        padding: 10px 0;
        overflow-x: auto;
}

.container_quadrature table {
        width: 100%;
        min-width: 1000px;
        border-collapse: collapse;
}

.container_quadrature table thead {
        width: 100%;
        background-color: var(--primaryColor);
        color: #FFFFFF;
}

.container_quadrature table thead tr th {
        padding: 5px 10px;
        border: 1px solid #FFFFFF;

}

.container_quadrature table tbody {
        width: 100%;
        background-color: #FFFFFF;
        color: #000000;
}

.active {
        background-color: var(--primaryColor);
        color: #FFFFFF !important;
}

.container_quadrature table tbody tr td {
        padding: 5px 10px;
}

.container_quadrature table tbody tr td>button {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 3px;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;
}

.container_quadrature table tbody tr td:last-child {
        display: flex;
        gap: 10px;
}

.container_quadrature table tbody tr td>button>svg {
        fill: #FFFFFF;
}

.container_quadrature table tbody tr td>button:last-child {
        background-color: #FF0000;
}

.container_quadrature table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_quadrature table tfoot {
        font-weight: bold;
        border-top: 1px solid #000000;
        font-size: 1.1em;
}