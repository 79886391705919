.container_reports {
        width: 100%;
        height: 100%;
        display: flex;
        overflow-x: scroll;
}

.container_reports table {
        width: 100%;
        min-width: 1000px;
}