.container_modal_voucher{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
}
.container_modal_voucher >div{
        position: relative;
        width:auto;
        max-height: 95%;
}

.container_modal_voucher >div > button{
        position: absolute;
        display: flex;
        background: red !important;
        border: 1px solid red;
        right: 0;
        top: -20px;
        align-items: center;
        justify-content: center;
}