.container_add_cash {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.container_add_cash form {
        display: flex;
        width: 100%;
        max-width: 500px;
        flex-direction: column;
        gap: 20px;
}

.container_add_cash form span {
        display: flex;
        width: 100%;
        flex-direction: column;
}

.container_add_cash form span:first-child {
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
}


.container_add_cash form span:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
}


.container_add_cash form span:last-child button {
        width: 120px;
        height: 30px;
        border: none;
        background-color: var(--primaryColor);
        border-radius: 5px;
        color: #FFFFFF;
        font-weight: bold;
        cursor: pointer;

}

.container_add_cash form span input,
.container_add_cash form span select {
        width: 100%;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
}

.box_seller {
        display: flex;
        width: 100%;
        flex-direction: row !important;
        gap: 10px;
}

.box_seller>div:first-child {
        display: flex;
        width: calc(100% - 100px);
        gap: 10px;
        flex-direction: column;
}

.box_seller>div:last-child {
        display: flex;
        width: 100px;
        align-items: flex-end;

}

.box_seller>div:last-child button {
        width: 100%;
        height: 30px;
        border: none;
        cursor: pointer;
        background-color: var(--primaryColor);
        border-radius: 5px;
        color: #FFFFFF;
        font-weight: bold;
}

.box_table_add_cash {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.box_table_add_cash table {
        width: 100%;
}

.box_table_add_cash table thead {
        background-color: var(--primaryColor);
        color: #FFFFFF;
}
.box_table_add_cash table tbody tr td button{
        width: 100px;
        height: 20px;
        border: none;
        cursor: pointer;
        background-color: var(--primaryColor);
        color: #FFFFFF;
        font-weight: bold;
}