.title_assigment {
        display: flex;
        width: 100%;
        font-size: 1.1em;
        gap: 10px;
        padding: 10px 0;
        align-items: center;
}

.title_assigment>button {
        width: 100%;
        max-width: 100px;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: var(--primaryColor);
        cursor: pointer;
        color: #FFFFFF;
        font-weight: bold;
}

.container_assignments {
        display: flex;
        width: 100%;
        padding: 10px 0;
        justify-content: center;
}

.container_assignments form {
        display: flex;
        width: 100%;
        max-width: 500px;
        flex-direction: column;
        row-gap: 10px;
}

.container_assignments form>h3 {
        text-align: center;
        padding: 10px;
}

.container_assignments form span {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
}

.container_assignments form span select,
.container_assignments form span input {
        width: 100%;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
}

.container_assignments form span textarea {
        width: 100%;
        min-width: 100%;
        min-height: 50px;
        max-height: 200px;
        border: 1px solid #ccc;
        border-radius: 5px;
}

.container_assignments form span:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
}

.container_assignments form span:last-child button {
        width: 100%;
        max-width: 100px;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: var(--primaryColor);
        cursor: pointer;
        color: #FFFFFF;
        font-size: 1.1em;
        font-weight: bold;
}

.container_list_asignments {
        width: 100%;
        overflow-x: scroll;
}

.container_list_asignments table {
        width: 100%;
        min-width: 1400px;
        border-collapse: collapse;
}

.container_list_asignments table thead tr th {
        font-size: 1em;
        font-weight: bold;
        color: #FFFFFF;
        padding: 5px;
        text-align: center;
        background-color: var(--primaryColor);
}

.container_list_asignments table tbody tr td {
        padding: 5px;
}

.container_list_asignments table tbody tr:nth-child(even) {
        background-color: #d8d8d8;
}

.container_list_asignments table tbody tr td:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
}

.container_list_asignments table tbody tr td:last-child button {
        width: 25px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        cursor: pointer;
}

.container_list_asignments table tbody tr td:last-child button:first-child {
        background-color: var(--primaryColor);
}

.container_list_asignments table tbody tr td:last-child button:last-child {
        background-color: #FF0000;
}

.container_list_asignments table tbody tr td:last-child button svg {
        fill: #FFFFFF;
}

.container_filters {
        width: 100%;
        display: flex;
        padding: 5px;
        gap: 5px;
        flex-wrap: wrap;
}


.container_filters form {
        display: grid;
        width: 100%;
        grid-template-columns:18% 18% 150px 10%  1fr 30px 30px;
        gap: 10px;
}
.container_filters form span{
        display: flex;
        column-gap: 10px;
        align-items: center;
}

.container_filters form span input,
.container_filters form span select {
        width: 100%;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
        padding: 0 10px;
}

.container_filters form button {
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 5px;
}

.switch {
        --button-width: 3.5em;
        --button-height: 27px;
        --toggle-diameter: 1.3em;
        --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
        --toggle-shadow-offset: 10px;
        --toggle-wider: 3em;
        --color-grey: #cccccc;
        --color-green: var(--primaryColor);
        display: flex;
        align-items: center;
        gap: 10px;
}

.slider {
        display: inline-block;
        width: var(--button-width);
        height: var(--button-height);
        background-color: var(--color-grey);
        border-radius: calc(var(--button-height) / 2);
        position: relative;
        transition: 0.3s all ease-in-out;
}

.slider::after {
        content: "";
        display: inline-block;
        width: var(--toggle-diameter);
        height: var(--toggle-diameter);
        background-color: #fff;
        border-radius: calc(var(--toggle-diameter) / 2);
        position: absolute;
        top: var(--button-toggle-offset);
        transform: translateX(var(--button-toggle-offset));
        box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
        transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
        background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
        box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
        display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
        width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
        transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}